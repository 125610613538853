<!-- Footer start -->
<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-sm-6">
        <div>
          <h5 class="mb-4 footer-list-title">Customer Support</h5>
          <p>Monday - Friday 9:00 AM - 6:00 PM</p>
          <p>Saturday 9:00 AM - 4:00 PM</p>
          <p>Sunday CLOSED</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div>
          <h5 class="mb-4 footer-list-title">Payment Center</h5>
          <p>Monday - Saturday 9:00 AM - 6:00 PM</p>
          <p>Sunday CLOSED</p>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div>
          <h5 class="mb-4 footer-list-title">Contact</h5>

          <div>
            <div class="media">
              <i-feather name="map-pin" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
              <div class="media-body">
                <p>14780 S Memorial Dr, Bixby, OK 74008</p>
              </div>
            </div>
            <div class="media">
              <i-feather name="mail" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
              <div class="media-body">
                <p>payments@nelsonautofinance.com</p>
              </div>
            </div>
            <div class="media">
              <i-feather name="phone" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
              <div class="media-body">
                <p> (918) 296-7885</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->

</footer>
<!-- Footer end -->

<!-- Footer alt start -->
<section class="bg-white py-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="float-sm-left">
          <a href="javascript: void(0);">
            <img src="assets/images/naf-small.png" alt="Nelson Auto Finance Tulsa, OK" height="20">
            Nelson Auto Finance
          </a>
        </div>
        <div class="float-sm-right mt-4 mt-sm-0">
          <p class="copyright-desc text-black-50 mb-0">{{year}} © Nelson Auto Finance. Created by Dealerbible</p>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- Footer alt start -->
