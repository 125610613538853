 <!-- Services start -->
 <section class="section bg-light" id="services">
   <div class="container">
     <div class="row justify-content-center">
       <div class="col-lg-8">
         <div class="text-center mb-5">
           <h5 class="text-primary text-uppercase small-title">Services</h5>
           <h4 class="mb-3">Services We Provide</h4>
           <p>Our customers have been successful for a number of reasons!  We do short term loans to ensure the vehicle will be paid off in a reasonable amount of time.  We take our customers transportation needs seriously, and do our best to keep the vehicle on the road and driving.  We also do credit reporting once a month! When payments are made on time it will significantly help rebuild your credit, or create credit for first time buyers.  If you’re looking for a vehicle, and would like Nelson Auto Finance to be your lien holder check out Nelson Car Country at www.carcountry.com. The sales staff will be happy to assist you in finding the perfect vehicle for you.</p>
         </div>
       </div>
     </div>
     <div class="row">
       <div class="col-xl-6 col-sm-6" *ngFor="let item of serviceData">
         <div class="text-center p-4 mt-3">
           <div class="avatar-md mx-auto mb-4">
             <span class="avatar-title rounded-circle bg-soft-primary">
               <i-feather name="{{item.icon}}" class="icon-dual-primary"></i-feather>
             </span>
           </div>
           <h5 class="font-18">{{item.title}}</h5>
           <p class="mb-0" [innerHTML]="item.text"></p>
         </div>
       </div>

     </div>
     <!-- end row -->

   </div>
   <!-- end container -->
 </section>
 <!-- Services end -->
