<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
  id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="logo text-uppercase" href="/">
      <img src="assets/images/naf-small.png" alt="Nelson Auto Finance Tulsa, OK" class="logo-dark " height="40" />
      NELSON AUTO FINANCE
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
        <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
          <a [ngxScrollTo]="'#home'" href="javascript: void(0);" class="nav-link">Home</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}">
          <a [ngxScrollTo]="'#services'" href="javascript: void(0);" class="nav-link">Services</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'FAQ'}">
          <a [ngxScrollTo]="'#FAQ'" href="javascript: void(0);" class="nav-link">FAQ's</a>
        </li>
      </ul>
      <button class="btn btn-success btn-rounded navbar-btn"><a href="tel:918-296-7885">918-296-7885</a></button>
      <button class="btn btn-success btn-rounded navbar-btn" (click)=onNavigate()>Pay Online</button>

    </div>
  </div>
</nav>
<!-- Navbar End -->
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
  <!-- Hero section Start -->
  <section class="hero-section" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="hero-wrapper mb-4">
            <p class="font-16 text-uppercase">Hassle Free</p>
            <h1 class="hero-title mb-4">Online <span class="text-primary">Payments</span>
            </h1>

            <p>Nelson Auto Finance is an auto loan company based out of Bixby, Oklahoma. We are a proud member of the Nelson Auto Group, and been in business since 2004! We offer financing to many pre-approved dealerships in the Tulsa area. Our goals are to maintain a positive relationship, provide exceptional customer service, and earn repeat business! We are committed to being respectful, understanding, and forthcoming with each of our customers. Easy simple online payments, connect and pay with ease.</p>

            <div class="mt-4">
              <a (click)=onNavigate() class="btn btn-primary mt-2 mr-2">Pay Online</a>
            </div>
          </div>

        </div>

        <div class="col-lg-6 col-sm-8">
          <div class="home-img mt-5 mt-lg-0">
            <img src="assets/images/home-img.png" alt="" class="img-fluid mx-auto d-block">
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Hero section End -->

  <app-services></app-services>
  <app-features></app-features>
  <app-footer></app-footer>
</div>
