<!-- Features start -->
<section class="section" id="FAQ">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="text-center mb-5">
          <h4 class="mb-3">Frequently Asked Questions</h4>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="text-center mb-5">
          <h4 class="text-primary text-uppercase small-title">Payment Questions</h4>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-6">
        <div>
          <h5>How do I make a payment online?</h5>
          <p class="mb-4">Go to www.myfexaccount.com or click the pay online link on our website www.nelsonautofinance.com. Click “Register for Free.” The link will send you to SOLERA. Solera is a multi-factor authentication program used for privacy protection. Click the “sign up now” link, and fill out the required fields. It will ask you to verify your email, and choose a method to receive the authentication code. Once the Solera setup is completed, you’ll need to register your loan with Fex Pay. Fex pay is where you’ll make the payment, and print receipts. You’ll need to use the stock number “insert NAF stock number” to register. The 2-step verification will be required each time you log in.</p>
        </div>
      </div>
      <div class="col-lg-6">
        <div>
          <h5>Where can I make a cash payment or mail a payment?</h5>
          <p class="mb-4">We have a Nelson Auto representative inside the Nelson Car Country located at 14780 S. Memorial Drive Bixby OK, 74008.</p>
        </div>
      </div>


      <div class="col-lg-6">
        <div>
          <h5>Is there a fee to pay by phone?</h5>
          <p class="mb-4">There’s no fee to use our automated payment system. When you call our main line and follow the prompts. We accept credit, debit, or ACH when applicable. Although if you choose to pay directly with a Nelson Auto representative a convenience fee of $4.95 is applied.</p>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="text-center mb-5">
          <h4 class="text-primary text-uppercase small-title">Loan and Account Questions</h4>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-6">
        <div>
          <h5>What is a simple interest loan?</h5>
          <p class="mb-4">A simple interest loan accrues interest daily from the day the contract is signed until the day it's paid off. Interest is based on the remaining principal, interest rate and number of days in the year. However, as the principal is paid down the monthly interest decreases as well. Principal payments do not move your due date forward or stop the interest from accruing daily.</p>
        </div>
      </div>
      <div class="col-lg-6">
        <div>
          <h5>Can I make payment arrangements?</h5>
          <p class="mb-4">For all payment arrangement request please call our main number to discuss with a representative. Please be aware the account must be at least 90 days old with established pay history for us to consider arrangements.</p>
        </div>
      </div>
      <div class="col-lg-6">
        <div>
          <h5>Does Nelson Auto offer a grace period?</h5>
          <p class="mb-4">Yes, we provide a 3 day grace period. Anything beyond this is considered late.</p>
        </div>
      </div>
      <div class="col-lg-6">
        <div>
          <h5>How can I change my due date or get a payment deferred?</h5>
          <p class="mb-4">Please contact a Nelson Auto Finance representative to see if you are eligible.</p>
        </div>
      </div>
      <div class="col-lg-6">
        <div>
          <h5>How do late payments affect my loan?</h5>
          <p class="mb-4">Please try to avoid late payments. You will be assessed with a late fee each time your payment goes over 10 days past due. By making on time payments, you’ll satisfy your loan balance and the interest by the intended maturity date.</p>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="text-center mb-5">
          <h4 class="text-primary text-uppercase small-title">Insurance Questions</h4>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-6">
        <div>
          <h5>What type of insurance is required?</h5>
          <p class="mb-4">We require a policy that includes liability, comprehensive, and collision.</p>
          <p><strong>Liability</strong></p>
          <p>Is a state law requirement. It covers bodily injury and property damages to others in case of an accident you caused.</p>
          <p><strong>Collison</strong></p>
          <p>Pays for damages to your vehicle in the event of an accident.</p>
          <p><strong>Comprehensive</strong></p>
          <p>Is designed to cover vandalism, theft, and other damages that are not in the result of an accident.</p>
        </div>
      </div>
      <div class="col-lg-6">
        <div>
          <h5>
            Do I have to continue making payments if I was involved in an accident and might be a total loss?</h5>
          <p class="mb-4">Borrowers remain responsible for making payments until the loan is paid off. If a total loss occurred, please contact us so we can begin working toward a settlement with your insurance company. After the insurance company pays the settlement you will be responsible for any deficiency balance.</p>
        </div>
      </div>
      <div class="col-lg-6">
        <div>
          <h5>Do you offer insurance?</h5>
          <p class="mb-4">We have a program called Collateral Protection Insurance. It covers Comprehensive & Collison only. If at any point we received notice of cancelled, lapsed or insufficient insurance we will automatically enroll your vehicle to our master policy. The insurance payment is set up according to the loan payment frequency, and is to be paid in congruence to the regular payment. If you choose to obtain your own insurance just send us the proof, and we will remove you from the program. Also keep in mind this does not include liability so that has to be purchased separately. Please contact our main number with any questions regarding CPI insurance.</p>
        </div>
      </div>
    </div>
    <!-- end row -->


  </div>
  <!-- end container -->
</section>
<!-- Features end -->
